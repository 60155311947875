<template>
  <div class="main-apps">
    <div class="apps-general-container">
      <div class="apps">
        <Header />
        <Wrapper>
          <div class="apps-general">
            <h1 class="apps-header">{{ $t("generalTabs.applications") }}</h1>
            <div class="elements-container">
              <Element v-for="item in brandConfig.appsFiles" :key="item.id" :item="item" :changeHeader="changeHeader"
                :changeSrc="changeSrc" />
            </div>
          </div>
        </Wrapper>
      </div>
      <div class="guides">
        <h1 class="apps-header">{{ $t("generalTabs.guides") }}</h1>
        <div class="first-guide-elements-container">
          <GuidesElement v-for="item in firstElements" :key="item.id" :item="item" :changeHeader="changeHeader"
            :changeSrc="changeSrc" />
        </div>
        <div class="second-guide-elements-container">
          <GuidesElement v-for="item in secondElements" :key="item.id" :item="item" :changeHeader="changeHeader"
            :changeSrc="changeSrc" />
        </div>
      </div>
      <div class="mobile-apps">
        <div class="elements-container">
          <div class="ios-headers-container">
            <h1 class="apps-header ios-header">
              {{ $t("generalTabs.forIos") }}
            </h1>
            <h1 class="apps-header ios-header">
              {{ $t("generalTabs.appsNames") }}
            </h1>
          </div>
          <div class="qr-container">
            <div class="qr-box">
              <vue-qr :text="brandConfig.apps.android" :size="120" :margin="0" />
              <a href="brandConfig.apps.android" rel="noreferrer noopener" target="_blank" class="qr-link">Android</a>
            </div>
            <div class="qr-box">
              <vue-qr :text="brandConfig.apps.ios" :size="120" :margin="0" />
              <a href="brandConfig.apps.ios" rel="noreferrer noopener" target="_blank" class="qr-link">iOS</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Envelope :hideEnvelope="false" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import VueQr from "vue-qr";

export default {
  components: {
    VueQr,
    Element: () => import("./Element.vue"),
    GuidesElement: () => import("./GuidesElement.vue"),
    Wrapper: () => import("@/components/Wrapper.vue"),
    Header: () => import("@/components/SideNavPc.vue"),
    Envelope: () => import("@/components/Envelope.vue"),
  },
  computed: {
    ...mapGetters(["brandConfig"]),
    firstElements() {
      return this.brandConfig.appGuids.slice(0, 4);
    },
    secondElements() {
      return this.brandConfig.appGuids.slice(4);
    },
  },
  methods: {
    changeHeader(header) {
      const headerMappings = {
        "Android (STB/TV/Mobile/Amazon)": "Android/Amazon",
        "iPhone/iPad App": "iPhone/iPad",
      };
      return headerMappings[header] || header;
    },
    changeSrc(header) {
      const headerMappings = {
        "FLEX IPTV": require("@/components/assets/Apps/iptv-flex.svg"),
        "Android mobile": require("@/components/assets/Apps/fluent_phone-tablet-20-regular.svg"),
        "TIVImate": require("@/components/assets/Apps/tvmate.svg"),
        "VLC": require("@/components/assets/Apps/vlc-logo-player.svg"),
        "Kodi": require("@/components/assets/Apps/kodi.svg"),
        "Playberry": require("@/components/assets/Apps/playberry.svg"),
        "Startup show": require("@/components/assets/Apps/startup-show.svg"),
        "Flex IPTV": require("@/components/assets/Apps/iptv-flex.svg"),
        "IPTVSmarters": require("@/components/assets/Apps/iptv.svg"),
        "Firestick": require("@/components/assets/Apps/firestick.svg"),
        "Tivimate": require("@/components/assets/Apps/tvmate.svg"),
      };
      if (header.includes("Android BOX")) {
        return require("@/components/assets/Apps/Apps.svg");
      }

      return headerMappings[header] || header;
    },
  },
};
</script>

<style scoped>
h1,
p,
button,
input,
label,
span {
  font-family: "Wix Madefor Display", sans-serif;
  color: white;
  margin: 0;
}

.main-apps {
  display: flex;
  justify-content: space-between;
}

.apps {
  width: 100%;
  width: fit-content;
}

.apps-header {
  margin-bottom: 16px;
  font-size: 20px;
  font-weight: 600;
  color: white;
}

.ios-header {
  margin-bottom: 0;
  font-size: 20px;
  width: 60%;
}

.elements-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.guides,
.mobile-apps {
  padding: 24px 32px;
  background-color: rgba(31, 33, 39, 0.6);
  backdrop-filter: blur(10px);
  border-radius: 0 0 12px 12px;
  margin-top: 32px;
}

.mobile-apps {
  border-radius: 12px;
}

.and-symbol {
  font-size: 20px;
  line-height: 30px;
  margin: 0 16px;
}

.ios-apps-icons {
  cursor: pointer;
}

.first-guide-elements-container,
.second-guide-elements-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 16px;
}

.second-guide-elements-container {
  grid-template-columns: repeat(3, 1fr);
  margin: 10px 100px 0;
}

.qr-container {
  display: flex;
  gap: 60px;
}

.qr-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
}

.qr-link {
  padding: 6px 12px;
  border-radius: 8px;
  color: #918ffa;
  background-color: rgba(145, 143, 250, 0.1);
  text-decoration: none;
}

@media only screen and (max-width: 1500px) {
  .apps-general-container {
    width: 70%;
  }

  .apps {
    width: 100%;
  }
}

@media only screen and (max-width: 1090px) {
  .main-apps {
    flex-direction: column;
    align-items: center;
  }

  .apps-general-container {
    width: 100%;
  }
}

@media only screen and (max-width: 768px) {
  .elements-container {
    flex-direction: column;
    margin: 0;
    margin-top: 16px;
  }

  .first-guide-elements-container,
  .second-guide-elements-container {
    display: block;
    margin: 0;
  }

  .ios-headers-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-bottom: 32px;
  }

  .ios-header {
    width: 100%;
  }
}
</style>
